import { TitanAdvertiser, TitanAdvertiserPerformance } from '@cognitiv/galaxy-api';

export const titan_advertiser_performance_default: TitanAdvertiserPerformance = {
  spend_usd_today: 0,
  spend_usd_yesterday: 0,
  spend_usd_year: 0,
  spend_usd_today_abbr_formatted: '',
  spend_usd_yesterday_abbr_formatted: '',
  spend_usd_year_abbr_formatted: '',
  impressions_today: 0,
  impressions_yesterday: 0,
  impressions_year: 0,
  impressions_today_abbr_formatted: '',
  impressions_yesterday_abbr_formatted: '',
  impressions_year_abbr_formatted: '',
};

export const titan_advertiser_default: TitanAdvertiser = {
  ...titan_advertiser_performance_default,
  advertiser_name: '',
  advertiser_id: 0,
  partner_name: '',
  partner_id: 0,
  iab_category_name: '',
  iab_category_id: null,
  domain_url: '',
  state_id: 0,
  state_name: '',
  created_by: '',
  created_date: '',
  created_date_formatted: '',
  created_by_user_id: 0,
  hyperion_error: false,
  uuid: '',
};
