import React, { useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ClientProvider } from '@cognitiv/galaxy-api';
import { useDetectAdBlock } from 'adblock-detect-react';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { selectUser } from 'ducks/user/selectors';
import { clearUser } from 'ducks/user/slices';
import { setAutoFreeze } from 'immer';
import PersonasTemplate from 'products/hyperion/template/ApplicationTemplate';
import LibraTemplate from 'products/libra/template/ApplicationTemplate';
import OrionTemplate from 'products/orion/template/ApplicationTemplate';
import ErrorPage from 'products/public/pages/error/ErrorPage';
import ErrorTemplate from 'products/public/pages/error/templates/ErrorTemplate';
import LoginPage from 'products/public/pages/login/LoginPage';
import RequestPasswordPage from 'products/public/pages/request-password/RequestPasswordPage';
import ResetPasswordPage from 'products/public/pages/reset-password/ResetPasswordPage';
import ApplicationTemplate from 'products/scorpio/templates/ApplicationTemplate';
import { TITAN_ORGANIZATION } from 'products/titan/enums';
import TitanTemplate from 'products/titan/template/ApplicationTemplate';
import { FEATURES, HYPERION, LIBRA, ORION, PRIVATE, PUBLIC, SCORPIO, TITAN } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Suspense } from 'Suspense';
import { PrivateNestedRoute, PrivateRoute } from 'templates/PrivateRoute';
import PrivateTemplate from 'templates/PrivateTemplate';
import PublicTemplate from 'templates/PublicTemplate';
import { deleteLocalStorage, getLocalStorage } from 'utils/storage';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_SERVICE,
  REACT_APP_SOL,
  REACT_APP_HYPERION,
  REACT_APP_TITAN,
  REACT_APP_ORION,
  REACT_APP_LIBRA,
  REACT_APP_VIRGO,
  REACT_APP_SCORPIO,
} = process.env;

setAutoFreeze(false);

export const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser, shallowEqual);
  const adblockDetected = useDetectAdBlock();

  const adblockError = useCallback(() => {
    dispatch(
      updateModal({
        error: {
          is_open: true,
          name: 'Adblocker Installed',
          message:
            "We've detected an issue reaching our servers. If you are using an adblocker, please disable it. If you continue to have issues, contact Cognitiv.",
        },
      }),
    );
  }, [dispatch]);

  if (adblockDetected) {
    adblockError();
  }

  const errorCallback = useCallback(
    (err: any) => {
      dispatch(updateSettings({ loading: false, loading_statistics: false }));
      if (err.status === 401 || err.error === 'HashMismatch') {
        dispatch(clearUser());
        deleteLocalStorage('user');
        return err;
      }

      if (adblockDetected) {
        adblockError();
        return;
      }

      dispatch(
        updateModal({
          error: {
            is_open: true,
            name: err.name || null,
            message: err.message || null,
            error: err.error || null,
            status: err.status || null,
            stack: err.stack || null,
            raw: err.raw || null,
          },
        }),
      );
    },
    [adblockDetected, adblockError, dispatch],
  );

  useEffect(() => {
    const { private_key, public_key } = user;
    const settings = getLocalStorage('settings');
    const theme = settings.theme || 'light';

    if (private_key && public_key) {
      dispatch(updateSettings({ ...settings, theme }));
    }
    dispatch(updateSettings({ theme }));
  }, [dispatch, user, user.private_key, user.public_key]);

  return (
    <ClientProvider
      service={REACT_APP_SERVICE || 'error'}
      environment={REACT_APP_ENVIRONMENT || 'development'}
      version={REACT_APP_VERSION || '0.0.0'}
      host={{
        SOL: REACT_APP_SOL,
        HYPERION: REACT_APP_HYPERION,
        TITAN: REACT_APP_TITAN,
        LIBRA: REACT_APP_LIBRA,
        ORION: REACT_APP_ORION,
        VIRGO: REACT_APP_VIRGO,
        SCORPIO: REACT_APP_SCORPIO,
      }}
      errorCallback={errorCallback}
    >
      <BrowserRouter>
        <Routes>
          <Route path={PRIVATE.ROOT.path} element={<Navigate to={PUBLIC.LOGIN.path} />} />
          <Route path={PUBLIC.ROOT.path} element={<PublicTemplate />}>
            <Route path={PUBLIC.LOGIN.path} element={<LoginPage />} />
            <Route path={PUBLIC.PASSWORD_REQUEST.path} element={<RequestPasswordPage />} />
            <Route path={PUBLIC.PASSWORD_RESET.path} element={<ResetPasswordPage />} />
          </Route>
          <Route path={PRIVATE.ROOT.path} element={<PrivateTemplate />}>
            <Route path={LIBRA.APPLICATION.path} element={<LibraTemplate />}>
              <Route element={<PrivateRoute organizations={[TITAN_ORGANIZATION.COGNITIV, TITAN_ORGANIZATION.ADFORM]} include />}>
                <Route path={LIBRA.DSP_SEAT_IDENTIFIERS.path} element={<Suspense element={LIBRA.DSP_SEAT_IDENTIFIERS.element} />} />
              </Route>
            </Route>
            <Route path={TITAN.APPLICATION.path} element={<TitanTemplate />}>
              <Route path={TITAN.ADVERTISERS.path} element={<Suspense element={TITAN.ADVERTISERS.element} />} />
              <Route path={TITAN.ADVERTISER.path} element={<Suspense element={TITAN.ADVERTISER.element} />}>
                <Route path="" element={<PrivateNestedRoute organizations={[TITAN_ORGANIZATION.ADFORM, TITAN_ORGANIZATION.ADFORM_SALES]} />}>
                  <Route path={`${TITAN.ADVERTISER.INSIGHTS.path}/*`} element={<Suspense element={TITAN.ADVERTISER.INSIGHTS.element} />} />
                </Route>
                <Route path={`${TITAN.ADVERTISER.CONTEXTUAL.path}/*`} element={<Suspense element={TITAN.ADVERTISER.CONTEXTUAL.element} />} />
              </Route>
            </Route>
            <Route path={SCORPIO.APPLICATION.path} element={<ApplicationTemplate />}>
              <Route path="" element={<Suspense element={TITAN.ADVERTISERS.element} />} />
              <Route
                path={SCORPIO.INSIGHTS.path}
                element={<PrivateRoute organizations={[TITAN_ORGANIZATION.ADFORM, TITAN_ORGANIZATION.ADFORM_SALES]} />}
              >
                <Route path={SCORPIO.INSIGHTS.DEFAULT.path} element={<Suspense element={SCORPIO.INSIGHTS.DEFAULT.element} />} />
                <Route path={SCORPIO.INSIGHTS.EVENTS.path} element={<Suspense element={SCORPIO.INSIGHTS.EVENTS.element} />} />
                <Route path={SCORPIO.INSIGHTS.CAMPAIGNS.path} element={<Suspense element={SCORPIO.INSIGHTS.CAMPAIGNS.element} />} />
                <Route path={SCORPIO.INSIGHTS.MODELS.path} element={<Suspense element={SCORPIO.INSIGHTS.MODELS.element} />} />
                <Route path={SCORPIO.INSIGHTS.EVENT.path} element={<Suspense element={SCORPIO.INSIGHTS.EVENT.element} />}>
                  <Route path={SCORPIO.INSIGHTS.EVENT.DEFAULT.path} element={<Suspense element={SCORPIO.INSIGHTS.EVENT.DEFAULT.element} />} />
                  <Route path={FEATURES.DEMOGRAPHICS.path} element={<Suspense element={FEATURES.DEMOGRAPHICS.element} />} />
                  <Route path={FEATURES.KEYWORDS.path} element={<Suspense element={FEATURES.KEYWORDS.element} />} />
                  <Route path={FEATURES.DOMAINS.path} element={<Suspense element={FEATURES.DOMAINS.element} />} />
                  <Route path={FEATURES.GEOGRAPHICS.path} element={<Suspense element={FEATURES.GEOGRAPHICS.element} />} />
                  <Route path={FEATURES.TEMPORALS.path} element={<Suspense element={FEATURES.TEMPORALS.element} />} />
                </Route>
                <Route path={SCORPIO.INSIGHTS.CAMPAIGN.path} element={<Suspense element={SCORPIO.INSIGHTS.CAMPAIGN.element} />}>
                  <Route path={SCORPIO.INSIGHTS.CAMPAIGN.DEFAULT.path} element={<Suspense element={SCORPIO.INSIGHTS.CAMPAIGN.DEFAULT.element} />} />
                  <Route path={FEATURES.STATISTICS.path} element={<Suspense element={FEATURES.STATISTICS.element} />} />
                  <Route path={FEATURES.DEMOGRAPHICS.path} element={<Suspense element={FEATURES.DEMOGRAPHICS.element} />} />
                  <Route path={FEATURES.DOMAINS.path} element={<Suspense element={FEATURES.DOMAINS.element} />} />
                  <Route path={FEATURES.GEOGRAPHICS.path} element={<Suspense element={FEATURES.GEOGRAPHICS.element} />} />
                  <Route path={FEATURES.TEMPORALS.path} element={<Suspense element={FEATURES.TEMPORALS.element} />} />
                </Route>
                <Route path={SCORPIO.INSIGHTS.MODEL.path} element={<Suspense element={SCORPIO.INSIGHTS.MODEL.element} />}>
                  <Route path={SCORPIO.INSIGHTS.MODEL.DEFAULT.path} element={<Suspense element={SCORPIO.INSIGHTS.MODEL.DEFAULT.element} />} />
                  <Route path={FEATURES.PREDICTIONS.path} element={<Suspense element={FEATURES.PREDICTIONS.element} />} />
                  <Route path={FEATURES.FEATURES.path} element={<Suspense element={FEATURES.FEATURES.element} />} />
                  <Route path={FEATURES.USER_JOURNEYS.path} element={<Suspense element={FEATURES.USER_JOURNEYS.element} />} />
                  <Route path={FEATURES.PLANNING.path} element={<Suspense element={FEATURES.PLANNING.element} />} />
                </Route>
              </Route>
            </Route>
            <Route path={HYPERION.APPLICATION.path} element={<PersonasTemplate />}>
              <Route
                path={HYPERION.INSIGHTS.path}
                element={<PrivateRoute organizations={[TITAN_ORGANIZATION.ADFORM, TITAN_ORGANIZATION.ADFORM_SALES]} />}
              >
                <Route path={HYPERION.INSIGHTS.PERSONAS.path} element={<Suspense element={HYPERION.INSIGHTS.PERSONAS.element} />} />
                <Route path={HYPERION.INSIGHTS.PERSONA_VERSIONS.path} element={<Suspense element={HYPERION.INSIGHTS.PERSONA_VERSIONS.element} />} />
                <Route path={HYPERION.INSIGHTS.PERSONA.path} element={<Suspense element={HYPERION.INSIGHTS.PERSONA.element} />} />
              </Route>
            </Route>
            <Route path={ORION.APPLICATION.path} element={<OrionTemplate />}>
              <Route
                element={
                  <PrivateRoute
                    organizations={[
                      TITAN_ORGANIZATION.COGNITIV,
                      TITAN_ORGANIZATION.ADFORM,
                      TITAN_ORGANIZATION.ADFORM_SALES,
                      TITAN_ORGANIZATION.SALES,
                    ]}
                    include
                  />
                }
              >
                <Route path={ORION.DEFAULT.path} element={<Suspense element={ORION.DEFAULT.element} />} />
                <Route path={ORION.DOMAIN_LISTS.path} element={<Suspense element={ORION.DOMAIN_LISTS.element} />} />
                <Route path={ORION.DOMAIN_LIST.path} element={<Suspense element={ORION.DOMAIN_LIST.element} />} />
                <Route path={ORION.CAMPAIGNS.path} element={<Suspense element={ORION.CAMPAIGNS.element} />} />
                <Route path={ORION.SEGMENTS.path} element={<Suspense element={ORION.SEGMENTS.element} />} />
                <Route path={ORION.DEALS.path} element={<Suspense element={ORION.DEALS.element} />} />
                <Route path={ORION.SEGMENT.path} element={<Suspense element={ORION.SEGMENT.element} />}>
                  <Route path={ORION.SEGMENT.DEFAULT.path} element={<Suspense element={ORION.SEGMENT.DEFAULT.element} />} />
                  <Route path={ORION.SEGMENT.FILTERS.path} element={<Suspense element={ORION.SEGMENT.FILTERS.element} />} />
                </Route>
                <Route path={ORION.CAMPAIGN.path} element={<Suspense element={ORION.CAMPAIGN.element} />}>
                  <Route path={ORION.CAMPAIGN.DEFAULT.path} element={<Suspense element={ORION.CAMPAIGN.DEFAULT.element} />} />
                  <Route path={ORION.CAMPAIGN.SEGMENTS.path} element={<Suspense element={ORION.CAMPAIGN.SEGMENTS.element} />} />
                  <Route path={ORION.CAMPAIGN.DEALS.path} element={<Suspense element={ORION.CAMPAIGN.DEALS.element} />} />
                </Route>
                <Route path={ORION.DEAL.path} element={<Suspense element={ORION.DEAL.element} />}>
                  <Route path={ORION.DEAL.DEFAULT.path} element={<Suspense element={ORION.DEAL.DEFAULT.element} />} />
                  <Route path={ORION.DEAL.FILTER.path} element={<Suspense element={ORION.DEAL.FILTER.element} />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<ErrorTemplate />}>
              <Route path="*" element={<ErrorPage path={`${TITAN.APPLICATION.path}/${TITAN.ADVERTISERS.path}`} page="Advertisers" />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ClientProvider>
  );
};
