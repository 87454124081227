export enum SLICE {
  TITAN_ADVERTISER = 'titan-advertiser',
  TITAN_ADVERTISERS = 'titan-advertisers',
  TITAN_CREATIVE_TYPES_LIST = 'titan-creative-types-list',
  TITAN_INVENTORY_SOURCES_LIST = 'gemini-inventory-sources-list',
  TITAN_PARTNERS = 'titan-partners',
  TITAN_CAMPAIGN_ROLE_PERSONS = 'titan-campaign-role-persons',
  TITAN_CAMPAIGNS = 'titan-campaigns',
  TITAN_EVENT_SOURCES = 'titan-event-sources',
}
